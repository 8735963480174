import { Injectable, inject } from '@angular/core';
import { CipherService } from './cipher.service';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, concatMap, map } from 'rxjs';
import { Catalog, Category, Product } from '../interfaces/catalog';
import { environment } from 'src/environments/environment';
import { LocalStorage } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  @LocalStorage('catalogUpdated', {})
  public catalogUpdated$!: ReplaySubject<Catalog>;

  private httpClient = inject(HttpClient);

  constructor(private cipherService: CipherService) { }

  public retrieveCatalog(storeGuid: string): Observable<Catalog> {
    const request = {
       customerEmail: ''
    }

   const obs = this.cipherService.initializeSymKey(storeGuid).pipe(concatMap((symKey: string)=>{
        return this.httpClient.post<string>(environment.apiUrl + '/store/' + storeGuid + '/getassets', request)
        .pipe(concatMap((catalog: string) => {
            return this.cipherService.decryptWithSymPrivateKey(symKey, catalog).pipe(map((decryptedCatalog: Catalog)=>{
                this.catalogUpdated$.next(decryptedCatalog);
                return decryptedCatalog;
            }))
        }));
    }));

    return obs;
  }

  public getSingleProductPrice(product: Product) {
      if (product.CampaignTitle && product.CampaignTitle !== '')
      {
        return product.ProductCampaignDetailsDto.DiscountedPrice
      }
      return product.PriceInclVat;
  }

  public getProductById(productId: number): Observable<Product> {
      return this.catalogUpdated$.pipe(map((catalog: Catalog)=>{
        return catalog.Products.filter(product => product.ProductId === productId)[0];
      }));
  }

  public getSubCategories(parentCategoryNumber: number): Observable<Category[]> {
    return this.catalogUpdated$.pipe(map((catalog: Catalog)=>{
        const subCategories = catalog.Categories.filter(cat => cat.CategoryParentNumber === parentCategoryNumber);
        if (subCategories && subCategories.length > 0)
            return catalog.Categories.filter(cat => cat.CategoryParentNumber === parentCategoryNumber);
        else
            return catalog.Categories.filter(cat => cat.CategoryNumber === parentCategoryNumber);
    }));
}

}
