import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CipherService {

  constructor(
    private httpClient: HttpClient) { }

  public initializeSymKey(storeGuid: string) {
    return this.generateRsaCryptoKeyPair().pipe(concatMap((key: CryptoKeyPair) => {
      return this.exportKey(key.publicKey).pipe(concatMap((keyBuffer: ArrayBuffer) => {
        const base64String = this.arrayBufferToBase64(keyBuffer);
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        return this.httpClient.post<string>(`${environment.apiUrl}/store/${storeGuid}/getcatalogasset`, JSON.stringify(base64String), { headers: headers })
        .pipe(concatMap((result: string) => {
          return this.decryptWithRsaPrivateKey(key.privateKey, result).pipe(map((symKey: string) => {
            return symKey;
          }));
        }));
      }));
    }));
  }

  public decryptWithSymPrivateKey(key: string, data: string) {
    var encryptedArrayBuffer = this.base64ToArrayBuffer(data)

    return this.importSymKey(key).pipe(concatMap((symKey) => {
      return from(window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv: encryptedArrayBuffer.slice(0, 12),
          tagLength: 128,
        },
        symKey,
        encryptedArrayBuffer.slice(12)
      )
        .then(function (decrypted) {
          var decoded = new TextDecoder().decode(new Uint8Array(decrypted));
          var parsed = JSON.parse(decodeURIComponent(escape(atob(decoded))));
          return parsed;
        })
      );
    }));
  }

  private generateRsaCryptoKeyPair(): Observable<CryptoKeyPair> {
    return from(window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: { name: "SHA-256" },
      },
      true,
      ["encrypt", "decrypt"]
    )
      .then(function (key: CryptoKeyPair) {
        return key;
      })
    );
  }

  private exportKey(pk: CryptoKey): Observable<ArrayBuffer> {
    return from(window.crypto.subtle.exportKey(
      "spki",
      pk
    ).then(function (keydata) {
        return keydata;
    }));
  }

  private decryptWithRsaPrivateKey(key: CryptoKey, data: string) {
    var encryptedArrayBuffer = this.base64ToArrayBuffer(data)
    return from(window.crypto.subtle.decrypt(
      {
        name: "RSA-OAEP"
      },
      key,
      encryptedArrayBuffer
    )
      .then(function (decrypted) {
        var string = new TextDecoder().decode(new Uint8Array(decrypted));
        return string;
      })
    );
  }

  private importSymKey(key: string) {

    return from(window.crypto.subtle.importKey(
      "raw",
      this.base64ToArrayBuffer(window.btoa(key)),
      {
        name: "AES-GCM",
      },
      true,
      ["encrypt", "decrypt"]
    )
      .then(function (key) {
        return key;
      }));
  }

  private base64ToArrayBuffer(base64: string) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
